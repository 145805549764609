<template>
  <div class="mx-auto w-140 px-6 py-8 text-center">
    <div class="px-24 py-16">
      <div v-if="photo" class="mb-4 text-gray-800 text-center">
        {{ $t('photoWasUploaded') }}
      </div>
      <Button class="w-full mb-6" @click="toHome">
        {{ $t('toHome') }}
      </Button>
      <Button
        v-if="savedContract"
        id="download_pdf"
        class="w-full mb-6"
        @click="$store.dispatch('getContractPDF', savedContract.contractId)"
      >
        {{ $t('downloadContractAsPdf') }}
      </Button>
      <!-- TODO: Button expects string and not object for :to prop -->
      <Button
        v-if="savedContract && !photo"
        class="w-full mb-6"
        :to="{ name: 'photo', params: { studio: $route.params.studio, id: savedContract.customerId} }"
      >
        {{ $t('addCustomerPhoto') }}
      </Button>
      <!-- <Button class="w-full">
        {{ $t('arrangeMeeting') }}
      </Button> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      photo: false,
    };
  },

  computed: {
    savedContract() {
      return this.$store.state.contract.savedContract;
    },
  },

  created() {
    this.photo = !!this.$route.query.photo;
  },

  methods: {
    toHome() {
      this.$store.commit('resetSavedContractData');
      this.$store.dispatch('removeSearchResults');
      this.$router.push({ name: 'contracts', params: { studio: this.$route.params.studio } });
    },
  },
};
</script>
